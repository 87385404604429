.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  /* background-image: url("../public/dark-galaxy-background.jpg"); */
  /* background-image: url("../public/profile.jpeg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  font-family: "Source Sans Pro", sans-serif;
}
.background-pic {
  position: fixed;
  height: 150%;
  /* width: 300vh; */
  object-fit: cover;
  top: 400;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.background-canvas {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: -1;
}
.content-container {
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: appear 0.5s;
}
a {
  text-decoration: none;
}
a:visited {
  color: inherit;
}
@keyframes appear {
	0% {
		opacity: 0;
	}
    100% {
		opacity: 1;
	}
}

@media screen and (max-width: 800px) {
  .background-pic {
    height: 150%;
  }
}

