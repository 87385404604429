.header {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    color: aliceblue;
    display: flex;
    flex-direction: row;
    margin-top: 0;
    width: 100%;
    height: 70px;
    transition: all 0.5s;
}
.nav-container {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: row;
}
.nav-button-container {
    height: 100%;
    width: 90px;
}
.nav-container .nav-button {
    height: 100%;
    width: 100%;
    background: none;
	color: white;
	border: none;
	font: inherit;
	cursor: pointer;
	/* outline: inherit; */
    transition: all 0.5s;
}
.nav-container .nav-button:hover {
    font-size: 20px;
    color: rgba(219, 219, 219, 0.795);
    transition: all 0.5s;
}
.logo-container {
    display: flex;
    height: 65px;
    width: 65px;
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0);
    /* border-radius: 50%;
    border: solid #ffffff9a;
    border-width: 2px; */
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
}
.logo-container span{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: xx-large;
    font-weight: 900;
}
.header a {
    margin-top: auto;
    margin-bottom: auto;
}
.display-none {
    display: none;
}
.translate {
    /* margin-left: 200px; */
}
.white {
    background-color: rgba(0, 0, 0, 0.575);
}

.dropdown-nav-container button{
    background: none;
	color: white;
	border: none;
	font: inherit;
	cursor: pointer;
}
.dropdown-nav-container {
    background-color: rgba(22, 22, 22, 0.877);
    position: absolute;
    left: 0;
    display: none;
    flex-direction: column;
    width: 100%;
    font-size: x-large;
}
.dropdown-nav-button-container {
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.dropdown-nav-button-hamburger {
    display: none;
    padding: 5px;
    z-index: 5;
}
.dropdown-nav-button-hamburger img{
    height: 60px;
}
.dropdown-nav-button {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}
.visible {
    display: flex;
    animation: slidein 0.2s ease-out;
}
@keyframes slidein {
    0% {
        opacity: 0;
        top: -300px;

    }
    100% {
        opacity: 1;
        top: 0;
    }
}
.invisible {
    animation: disappear 0.2s ease-in-out;
}
@keyframes disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 800px) {
    .nav-container .nav-button:hover {
        font-size: inherit;
        color: white;
    }
}

@media (max-width: 600px) {
    .dropdown-nav-button-hamburger {
        display: flex;
        margin-right: 0;
        margin-left: auto;
    }
    .nav-container {
        display: none;
    }
    .logo-container {
        display: none;
    }
}
