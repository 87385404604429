.portfolio-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.projects-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.portfolio-container h2 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: xx-large;
}
.portfolio-description {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.portfolio-container img{
    /* height: 200px; */
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}
.project-container {
    height: 450px;
    margin: 15px;
    background-color: rgb(37, 37, 37);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.02), 0 0 12px 10px rgba(0, 0, 0, 0.1), 0 0px 25px 20px rgba(0, 0, 0, 0.2);
    /* border: 2px solid rgb(48, 48, 48); */
    max-width: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    
}
.project-description {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}