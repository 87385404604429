.solutions-container {
    margin: 15px;
    font-size: x-large;
}

.solution-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100%-40px);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.solution-section img {
    max-height: 300px;
    float: left;
    margin-bottom: 20px;
    margin-right: 20px;
}
.solution-section h2 {
    width: 100%;
    margin-bottom: 20px;
}
.solution-section p {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
}
.float-right {
    float:right;
}


@media screen and (max-width: 600px) {
    .solution-section img {
        max-width: 90%;
        object-fit: contain;
        margin-right: 30px;
        font-size: small;
    }
    .solution-section p{
        font-size: medium;
    }
}