.home-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
}
.home-row-1 {
    display: flex;
    flex-direction: row;
}
.profile-container {
    /* height: 300px;
    width: 600px; */
    /* background-color: rgba(36, 29, 29, 0.356); */
    background-color: rgba(0, 0, 0, 0);
    margin: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.profile-container *{
    margin: auto;
}
.profile-container .profile-img{
    height: 200px;
    border-radius: 50%;
    border: solid #4949499a;
    border-width: 2px;
    margin: auto;
    cursor: pointer;
}
.profile-container .profile-name {
    font-size: 40px;
}
.profile-container .profile-location {
    font-size: 30px;
}
.social-links {
    height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.social-links .social-links-img-container{
    height: 100%;
    width: 80px;
    display: flex;
}
.social-links .social-links-img{
    height: 40px;
    margin: auto;
    transition: all 0.5s;
}
.social-links .social-links-img:hover{
    height: 55px;
}
.info-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(255, 255, 255, 0);
    color: rgb(255, 255, 255);
}
.intro-container {
    display: flex;
    width: auto;
    margin-left: 100px;
    margin-right: 100px;
    font-size: large;
}
.intro-container p, .intro-container h2{
    margin-top: 20px;
    margin-left: 0;
    margin-right: auto;
}
.intro-container p{
    text-align: justify;
}
.home-container .row {
    background-color: rgba(0, 0, 0, 0);
    width: auto;
    display: flex;
    flex-direction: row;
    margin-left: 100px;
    margin-right: 100px;
}
.background-separator {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 30px;
    padding-bottom: 30px;
}

.row .info-container {
    width: 50%;
}
.info-container h2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}
.skill-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; */
    /* animation: rainbow_animation 4s ease-in-out infinite; */

}
.skill-container span{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 7px;
    border-radius: 20px;
    background-color: rgba(189, 189, 189, 0);
    border: 2px solid rgba(255, 255, 255, 0);
    font-weight: 700;
}

@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
}

@media screen and (max-width: 800px){
    .row .info-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .info-container {
        padding-left: 0;
        padding-right: 0;
    }
    .info-container span{
        font-size: medium;
    }
    .intro-container {
        margin-left: 20px;
        margin-right: 20px;
    }
    .home-container .row {
        flex-direction: column;
        margin-left: 5px;
        margin-right: 5px;
    }
}

