.contact-form {
    /* display: flex;
    flex-direction: column; */
    font-size: x-large;
    width: 800px;
    margin-top: 20px;
    /* background-color: rgba(0, 0, 0, 0.836);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.02), 0 0 12px 10px rgba(0, 0, 0, 0.1), 0 0px 25px 20px rgba(0, 0, 0, 0.2); */
    border-radius: 3px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    color: rgb(255, 255, 255);
  }
  .contact-form form {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
  }
  .contact-form form button{
    margin-left: auto;
    margin-right: auto;
  }
  
  .contact-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 30px;
    color: #ffffff;
    background-color: #00000000;
    font-family: arial;
    border: none;
    border-bottom: solid;
    border-width: 2px;
    border-color: rgba(165, 165, 165, 0.651);
  }
  
  .contact-form button {
    background-color: #13131334;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-form button:hover {
    background-color: #0000006c;
    transition: all 0.5s;
  }
  .contact-message {
    font-size: xx-large;
    margin-top: 15px;
    color: black;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .contact-message-text {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-form p {
    font-size: large;
    margin-bottom: 20px;
  }
  .contact-form h2 {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 800px) {
    .contact-form {
        font-size: x-large;
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
      }
  }