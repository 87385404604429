.footer-container {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    display: flex;
    margin-bottom: 0;
    margin-top: auto;
}
.footer {
    margin-left: auto;
    margin-right: auto;
}